<template>
  <div>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- Category -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="category"
                rules="required"
              >
                <b-form-group
                  :label="$t('Add_Product.Category')"
                  label-for="category"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="category"
                    v-model="categorySelect"
                    @change="getItems"
                    :state="categorySelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="categoryOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Brand -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="brand"
                rules="required"
              >
                <b-form-group
                  :label="$t('Add_Product.Brand')"
                  label-for="Brand"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="brand"
                    v-model="brandSelect"
                    @change="getItems"
                    :state="brandSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="brandOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Item -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="item"
                rules="required"
              >
                <b-form-group
                  :label="$t('Add_Product.Item')"
                  label-for="Item"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="item"
                    v-model="itemSelect"
                    :disabled="itemSe"
                    :state="itemSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="itemOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- active -->
            <!-- <b-col md="6">
                            <validation-provider #default="{ errors }" name="active" rules="required">
                                <b-form-group label="Active" label-for="active" :state="errors.length > 0 ? false:null">
                                    <v-select id="active" v-model="activeSelect" :state="activeSelect === null ? false : true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="activeOptions" :selectable="option => ! option.value.includes('select_value')" label="text" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col> -->
            <!-- price -->
            <!-- <b-col md="6">
                            <b-form-group label="Price" label-for="price">
                                <validation-provider #default="{ errors }" name="price" rules="required|regex:\b\d+(?:\.\d\d?)?\b">
                                    <b-form-input id="price" v-model="itemData.price" :state="errors.length > 0 ? false:null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col> -->
            <!-- quantity -->
            <!-- <b-col md="6">
                            <b-form-group label="Quantity" label-for="quantity">
                                <validation-provider #default="{ errors }" name="quantity" rules="required|integer">
                                    <b-form-input id="quantity" type="number" v-model="itemData.quantity" :state="errors.length > 0 ? false:null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col> -->
            <!-- submit button -->
            <b-col>
              <br />
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                {{ $t("Add_Product.Submit") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";

import { required } from "@validations";

import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";

export default {
  components: {
    flatPickr,
  },
  props: {
    cover: "",
  },
  setup(props) {
    const refInputEl = ref(null);
    const previewEl = ref(null);
    const refInputEl2 = ref(null);
    const previewEl2 = ref(null);
    const refInputEl3 = ref(null);
    const previewEl3 = ref(null);
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.cover = base64;
      }
    );
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
  watch: {
    categorySelect: function () {
      this.getItems();
    },
    brandSelect: function () {
      this.getItems();
    },
  },
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      id: 0,
      // cover: '',
      setupCover: "",
      categorySelect: {
        text: "",
        value: "",
      },
      categoryOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      brandSelect: {
        text: "",
        value: "",
      },
      brandOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      activeSelect: {
        text: "",
        value: "",
      },
      activeOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
        {
          value: "1",
          text: "True",
        },
        {
          value: "0",
          text: "False",
        },
      ],
      itemSelect: {
        value: "",
        text: "",
      },
      itemOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],

      vendorOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      vendorSelect: {
        value: "",
        text: "",
      },
      recData: {},
      itemSe: false,
      itemData: {
        category_id: "",
        brand_id: "",

        item_id: "",
      },
      required,
    };
  },
  created() {
    this.id = this.$route.params.id;
    axios
      .get("products/" + this.id)
      .then((result) => {
        const data = result.data.data;
        this.recData = data;

        if (data.category.translation.length > 0) {
          this.categorySelect.value = data.category.id.toString();
          this.categorySelect.text = data.category.translation[0].name;
        }
        if (data.brand.translation.length > 0) {
          this.brandSelect.value = data.brand.id.toString();
          this.brandSelect.text = data.brand.translation[0].name;
        }
        if (data.phoneGuide.item_translations.length > 0) {
          this.itemSelect.value = data.phoneGuide.id.toString();
          this.itemSelect.text = data.phoneGuide.item_translations[0].name;
        }
      })
      .catch((err) => {
        this.showDismissibleAlert = true;
        this.errors_back = err.response.data.data;
      });

    axios
      .get("products/create")
      .then((result) => {
        const data = result.data.data;
        if (data.categories.length > 0) {
          for (let ind in data.categories) {
            if (data.categories[ind].translation != null) {
              this.categoryOptions.push({
                text: data.categories[ind].translation.name,
                value: data.categories[ind].id.toString(),
              });
            }
          }
        }
        if (data.brands.length > 0) {
          for (let ind in data.brands) {
            if (data.brands[ind].translation != null) {
              this.brandOptions.push({
                text: data.brands[ind].translation.name,
                value: data.brands[ind].id.toString(),
              });
            }
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line

          this.itemData.category_id = this.categorySelect.value;
          this.itemData.brand_id = this.brandSelect.value;
          this.itemData.item_id = this.itemSelect.value;

          axios
            .put("products/" + this.id, this.itemData)
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.back(1);
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    getItems() {
      if (this.categorySelect != null && this.brandSelect != null) {
        axios
          .get(
            "items/" +
              this.categorySelect.value +
              "/" +
              this.brandSelect.value +
              "/get-items"
          )
          .then((result) => {
            const data = result.data.data;
            this.itemSe = false;
            if (data.length > 0) {
              for (let ind in data) {
                if (data[ind].item_translations != null) {
                  this.itemOptions.push({
                    text: data[ind].item_translations.name,
                    value: data[ind].id.toString(),
                  });
                }
              }
            }
          })
          .catch((err) => {
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
      }
    },
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.itemData.cover = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
 

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
#vs9__listbox {
  z-index: 99999 !important;
}
</style>
